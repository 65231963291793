import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { 
    SimpleList,
    List, 
    Datagrid, 
    ImageField, 
    TextField, 
    ShowButton,
    EditButton,
    TopToolbar,
    CreateButton,
    ExportButton,
    Create,
    SimpleForm,
    TextInput,
    Edit,
    ImageInput,
    useRecordContext
} from 'react-admin';

import ImgField from '../components/ImgField'
import ListFilter from '../components/ListFilter'


export const DefaultServicesList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List title="All Default Services" {...props}  actions={<TopToolbar><CreateButton/><ExportButton/></TopToolbar>}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.defaultDesc}
                />
            ) : (
                <Datagrid>
                    <TextField source="id" />
                    <TextField source="name" />
                    <TextField source="tags" />
                    <ImgField source="featuredImage"/>
                    <TextField source="defaultDesc" />
                    <ShowButton/>
                    <EditButton/>
                </Datagrid>
            )}
        </List>
    );
};

export const DefaultServicesCreate = (props) => (
    <Create {...props}>
        <SimpleForm>
            <h1>Create New:</h1>
            <TextInput source="name"  />
            <TextInput source="defaultDesc" />
            <TextInput source="tags" />
            <ImageInput source="picture" label="Related pictures" accept="image/*">
                <ImageField source="src" title="title" />
            </ImageInput>
        </SimpleForm>
    </Create>
);

export const DefaultServicesEdit = (props) => {
    const record = useRecordContext()

    return (
        <Edit {...props}>
            <SimpleForm>
                <h1>Edit:</h1>
                <TextInput source="name"/>
                <TextInput source="defaultDesc"  />
                <TextInput source="tags" />
                <p>Current Image:</p>
                <ImgField source="featuredImage"/>
                <ImageInput source="picture" label="Replace Image:" accept="image/*">
                    <ImageField source="src" title="title" />
                </ImageInput>
            </SimpleForm>
        </Edit>
    )
};
