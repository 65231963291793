import React , {useState} from 'react';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import TextField from '@material-ui/core/TextField';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';

import {httpClient} from '../utils/dataProvider'

export default () => {
    const [message, setMessage] = useState('')
    const sendBroadcast = () => {
        httpClient('https://skilzapp.net/api/notifications/broadcast',{
            method: 'POST',
            body: JSON.stringify({message:message}),
        }).then(({json}) => {
            setMessage('')
            window.alert('Broadcast Message Sent!')
        })
    }
    return (
    <Card>
        <CardHeader title="Welcome to Skilz Admin" />
        <CardContent>
        <TextField
          id="filled-full-width"
          label="Send Broadcast Notification"
          style={{ margin: 8}}
          placeholder="Message ..."
          fullWidth
          margin="normal"
          variant="outlined"
          value={message}
          onChange={(e)=>{setMessage(e.target.value)}}
        />
        <div  style={{display:'flex', flexDirection:'row-reverse'}}>
            <Button
                variant="contained"
                color="primary"
                endIcon={<Icon>send</Icon>}
                onClick={sendBroadcast}
            >
                Send
            </Button>
        </div>
        
        </CardContent>
        
    </Card>
)
};
