import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { 
    SimpleList,
    List, 
    Datagrid, 
    ImageField, 
    TextField, 
    ShowButton,
    EditButton,
    TopToolbar,
    CreateButton,
    ExportButton,
    Create,
    SimpleForm,
    TextInput,
    Edit,
    ImageInput,
    useRecordContext
} from 'react-admin';

export const UserList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List title="All users" {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.name}
                    secondaryText={record => record.role}
                />
            ) : (
                <Datagrid >
                    <TextField source="id" />
                    <ImageField source="profilePic"/>
                    <TextField source="name" />
                    <TextField source="role" />
                    <ShowButton/>
                    <EditButton/>
                </Datagrid>
            )}
        </List>
    );
};

export const UserEdit = (props) => {
    const record = useRecordContext()

    return (
        <Edit {...props}>
            <SimpleForm>
                <h1>Edit:</h1>
                <TextInput source="name"/>
                <TextInput source="role"/>
                <TextInput source="dateOfBirth"/>
                <TextInput source="gender"/>
                <TextInput source="city"/>
                <TextInput source="country"/>
            </SimpleForm>
        </Edit>
    )
};
