import * as React from 'react';
import { useMediaQuery } from '@material-ui/core';
import { SimpleList, List, Datagrid, EmailField, TextField, ShowButton, Show, SimpleShowLayout, DateField, RichTextField } from 'react-admin';
import ImgField from '../components/ImgField'

export const FeedList = props => {
    const isSmall = useMediaQuery(theme => theme.breakpoints.down('sm'));

    return (
        <List title="All users" {...props}>
            {isSmall ? (
                <SimpleList
                    primaryText={record => record.createdBy.name}
                    secondaryText={record => record.desc}
                />
            ) : (
                <Datagrid>
                    <TextField source="createdBy.name" />
                    <ImgField source="attachement"/>
                    <TextField source="desc" />
                    <ShowButton/>
                </Datagrid>
            )}
        </List>
    );
};
