import * as React from 'react';
import { useRecordContext} from 'react-admin';

export default ({ source }) => {
	const record = useRecordContext();
	const url = 'https://skilzcdn.nyc3.cdn.digitaloceanspaces.com/'+record[source]
	return (
	<div>
			<img src={url} title={record['name']} width='100px'/>
	</div>
	);
};