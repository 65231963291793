import React, { useState } from 'react';
import { useLogin, useNotify, Notification, defaultTheme } from 'react-admin';
import TextField from '@material-ui/core/TextField';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardHeader from '@material-ui/core/CardHeader';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon';

export default ({ theme }) => {
    const [phone, setPhone] = useState("+971")
    const [code, setCode] = useState("")
    const [step, setStep] = useState(1)
    const login = useLogin();
    const sendSMS = async () => {
        try {
            setStep(0)
            const rawResponse = await fetch('https://skilzapp.net/api/sendSMS', {
                method: 'POST',
                headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json'
                },
                body: JSON.stringify({phone})
            });
            const content = await rawResponse.json();
            if (content.msg === 'SUCCESS'){
                setStep(2)
            }
        } catch (error) {
            setStep(1)
            window.alert("Error Try again!")
        }
    }
    
    return (
        <div style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100vh',
            width:'100vw',
            background:'#272459'
        }}>
            <Card style={{
                width:350,
                height:350, 
                padding:10, 
                display:'flex', 
                flexDirection:'column',
                alignItems:'center',
                justifyContent:'space-around'
            }}>
                <img src='logo.png' width={100} height={100}></img>
                {
                    {
                        0: (<h2>Loading...</h2>),
                        1: ( <>
                        <CardHeader title="Welcome to Skilz Admin" />
                        <CardContent style={{display:'flex'}}>
                            <TextField value={phone} onChange={(e)=>setPhone(e.target.value)} style={{flex:2}} id="standard-basic" label="Phone NB" placeholder="+971 50 000 0000" variant='filled'/>
                            <Button
                                style={{flex:1, marginLeft:3}}
                                variant="contained"
                                color="primary"
                                endIcon={<Icon>send</Icon>}
                                onClick={()=>{
                                    if(/^\+[1-9]\d{1,14}$/.test(phone)){
                                        sendSMS()
                                        setStep(2)
                                    } else {
                                        window.alert('invalid number')
                                    }
                                    
                                }}
                            >Continue</Button>
                        </CardContent>
                        </>),
                        2: ( <>
                            <CardHeader title="Please Enter OTP" />
                            <CardContent style={{display:'flex'}}>
                                <TextField value={code} onChange={(e)=>setCode(e.target.value)} style={{flex:2}} id="standard-basic" label="OTP" placeholder="6-Digit Code" variant='filled'/>
                                <Button
                                    style={{flex:1, marginLeft:3}}
                                    variant="contained"
                                    color="primary"
                                    endIcon={<Icon>send</Icon>}
                                    onClick={()=>{
                                        if (code){
                                            login({phone, code}).then(res => console.log(res)).catch((e) =>
                                            console.log(e)
                                            )
                                        }
                                    }}
                                >Login</Button>
                            </CardContent>
                            </>),
                    }[step]
                }
                
            </Card>
                    
        </div>
    );
};
