export default {
    // called when the user attempts to log in
    login: async (body) =>  {
            try {
                const rawResponse = await fetch('https://skilzapp.net/api/authenticateSMS', {
                    method: 'POST',
                    headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(body)
                });
                const resp = await rawResponse.json();
                localStorage.setItem('auth', JSON.stringify(resp));
                return resp;
            } catch (error) {
                throw new Error('Network error')
            }
    },
    // called when the user clicks on the logout button
    logout: () => {
        localStorage.removeItem('auth');
        return Promise.resolve();
    },
    // called when the API returns an error
    checkError: ({ status }) => {
        // if (status === 401 || status === 403) {
        //     localStorage.removeItem('auth');
        //     return Promise.reject();
        // }
        return Promise.resolve();
    },
    // called when the user navigates to a new location, to check for authentication
    checkAuth: () => {
        const auth = localStorage.getItem('auth')
        if(auth){
            return JSON.parse(auth).user.role === 'admin' ? Promise.resolve() : Promise.reject()
            
        } else {
            localStorage.clear()
            return Promise.reject();
        }
    },
    // called when the user navigates to a new location, to check for permissions / roles
    getPermissions: () => Promise.resolve(),
};
