import { fetchUtils } from 'react-admin';
import { stringify } from 'query-string';

const apiUrl = 'https://skilzapp.net/api';
export const httpClient = (url, options = {}) => {
	if (!options.headers) {
			options.headers = new Headers({ Accept: 'application/json' });
	}
	const { token } = JSON.parse(localStorage.getItem('auth'));
	options.headers.set('Authorization', `Bearer ${token}`);
	return fetchUtils.fetchJson(url, options);
};

const convertFileToBase64 = file =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = () => resolve(reader.result);
        reader.onerror = reject;

        reader.readAsDataURL(file.rawFile);
    });

export default {
    getList: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify(params.filter),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.docs.map(doc => ({id:doc._id,...doc})),
            total: json.totalDocs,
        }));
    },

    getOne: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`).then(({ json }) => ({
            data: {id: json._id, ...json},
        })),

    getMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;
        return httpClient(url).then(({ json }) => ({ data: json.docs }));
    },

    getManyReference: (resource, params) => {
        const { page, perPage } = params.pagination;
        const { field, order } = params.sort;
        const query = {
            sort: JSON.stringify([field, order]),
            range: JSON.stringify([(page - 1) * perPage, page * perPage - 1]),
            filter: JSON.stringify({
                ...params.filter,
                [params.target]: params.id,
            }),
        };
        const url = `${apiUrl}/${resource}?${stringify(query)}`;

        return httpClient(url).then(({ headers, json }) => ({
            data: json.docs,
            total: json.totalDocs,
        }));
    },

    update: (resource, params) =>{
        if(resource == 'defaultservices' && params.data.tags && !Array.isArray(params.data.tags)){
            params.data.tags = params.data.tags.split(",")
        } 
        
        if(resource === 'defaultservices' && params.data.picture) {
            return convertFileToBase64(params.data.picture).then(base64Picture => {
                delete params.data.picture
                params.data.b64ImageData = base64Picture
                console.log(params.data)
                return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                    method: 'PATCH',
                    body: JSON.stringify(params.data),
                }).then(({json}) => ({data: {...json, id:json._id}}))
            })
        } else {
            return httpClient(`${apiUrl}/${resource}/${params.id}`, {
                method: 'PATCH',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({data: { ...json, id: json._id }}))
        }
        
    },

    updateMany: (resource, params) => {
        const query = {
            filter: JSON.stringify({ id: params.ids}),
        };
        return httpClient(`${apiUrl}/${resource}?${stringify(query)}`, {
            method: 'PUT',
            body: JSON.stringify(params.data),
        }).then(({ json }) => ({ data: json }));
    },

    create: (resource, params) => {
        if(resource == 'defaultservices' && params.data.tags && !Array.isArray(params.data.tags)){
            params.data.tags = params.data.tags.split(",")
        } 

        if(resource === 'defaultservices' && params.data.picture) {
            return convertFileToBase64(params.data.picture).then(base64Picture => {
                delete params.data.picture
                params.data.b64ImageData = base64Picture
                return httpClient(`${apiUrl}/${resource}`, {
                    method: 'POST',
                    body: JSON.stringify(params.data),
                }).then(({json}) => ({data: {...json, id:json._id}}))
            })
        } else {
            return httpClient(`${apiUrl}/${resource}`, {
                method: 'POST',
                body: JSON.stringify(params.data),
            }).then(({ json }) => ({data: { ...json, id: json._id }}))
        }
        
    },

    delete: (resource, params) =>
        httpClient(`${apiUrl}/${resource}/${params.id}`, {
            method: 'DELETE',
        }).then(({ json }) => ({ data: params.id })),

    deleteMany: (resource, params) => {
        params.ids.map(id => {
            httpClient(`${apiUrl}/${resource}/${id}`, {
                method: 'DELETE',
            }).then(({ json }) => ({ data: json }))
        })
        return new Promise((resolve,reject)=>{resolve()}).then(() => ({data:params.ids})) 
        
    },
};